<template>
    <div>
        <div :class="['notifications-container', isInsolateNotification ? 'isolate-container' : '']">
            <h3 :class="['title', isInsolateNotification ? 'back-icon' : '']">
                <back-button v-if="isInsolateNotification" /> Notification settings
            </h3>
            <div>
                <div class="notifications-settings">
                    <span>Turn off all notifications</span>
                    <switches
                        v-model="offAllNotification"
                        class="board-privacy"
                        type-bold="false"
                        :emit-on-mount="false"
                    />
                </div>
                <div v-for="notification of notifications" :key="notification.notifications_types_id">
                    <h3 v-if="notification.children.length > 0" class="notification-name">
                        {{ notification.name }}
                    </h3>
                    <div
                        v-for="children of notification.children"
                        :key="children.notifications_types_id"
                        class="notifications-settings"
                    >
                        <span>{{ children.name }}</span>
                        <switches
                            :value="Boolean(children.is_enabled)"
                            class="board-privacy"
                            type-bold="false"
                            :emit-on-mount="false"
                            @input="children.is_enabled = Number(!children.is_enabled); onActiveNotification(children.notifications_types_id)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Switches from "vue-switches";

export default {
    name: "NotificationsSetting",
    components: { 
        Switches,
        BackButton: () => import(/* webpackChunkname: "back-button" */ "@c/atoms/back-button")
    },
    data: () => ({
        notifications: [],
        is_public: [],
        offAllNotification: false
    }),
    computed: {
        ...mapGetters({
            userData: "User/userData"
        }),
        isInsolateNotification() {
            return this.$route.name === "web-app-profile-settings-notifications-insolate";
        }
    },
    watch: {
        offAllNotification() {
            this.allNotificationAction();
        }
    },
    mounted() {
        this.setNotificationsSettings(); 
    },
    methods: {
        async setNotificationsSettings() {
            const { data: NotificationsSettings } = await axios.get("/users/0/notifications");
            this.notifications = NotificationsSettings;
        },
        onActiveNotification(NotificationsTypesId) {
            axios.put(`/users/${ this.userData.id }/notifications/${ NotificationsTypesId }`);
        },
        async allNotificationAction() {
            if (!this.offAllNotification) {
                return;
            }

            const toggleAll = this.notifications.map(notification => (
                {
                    ...notification,
                    children: notification.children.map(children => ({ ...children, is_enabled: Number(!this.offAllNotification) }))
                }
            ));

            this.notifications = toggleAll;

            await axios.delete(`/users/${ this.userData.id }/notifications`);
        }
    }
}
</script>


<style lang="scss" scoped>
 .notifications-container {
    padding: 0 30px;

    .title {
        margin-bottom: 40px;
    }

    .back-icon {
        display: flex;
        align-items: center;
    }

    .notification-name {
        color: rgba(235, 235, 245, 0.6);
        font-size: 16px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(84, 84, 88, 0.65);
    }

    .notifications-settings {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 16px;
        }
    }
 }

.isolate-container {
    margin-top: 100px;
}
</style>